import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <Parallax bgImage={mobileAbout} bgImageAlt="Entree Dish" strength={1}>
            <div style={{ height: '300px' }} />
          </Parallax>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto" w="100%" maxWidth="1400px">
          <CFImage
            w="100%"
            src={about}
            alt="Umami Sushi Japanese Restaurant hero text"
            zIndex={98}
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
